/* Alert */
.c-alert {
  background-color: lighten($color-default-10, 50%);
  border: 1px solid $color-default-10;
  border-radius: $border-radius;
  color: $color-default-base;
  margin: 0 0 $space-20;
  padding: $space-20 $space-default;
  position: relative;

  &__dismiss {
    background: none;
    border: none;
    padding: $space-20;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

/* Primary alert */
.c-alert--primary {
  background-color: lighten($color-primary-base, 70%);
  border-color: lighten($color-primary-base, 40%);
  color: darken($color-primary-base, 20%);
}

/* Success alert */
.c-alert--success {
  background-color: lighten($color-success, 70%);
  border-color: lighten($color-success, 40%);
  color: lighdarkenten($color-success, 20%);
}

/* Danger alert */
.c-alert--danger {
  background-color: lighten($color-danger, 70%);
  border-color: lighten($color-danger, 40%);
  color: darken($color-danger, 20%);
}

/* Warning alert */
.c-alert--warning {
  background-color: lighten($color-warning, 70%);
  border-color: lighten($color-warning, 40%);
  color: darken($color-warning, 20%);
}
