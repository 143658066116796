/* Form fieldset */
.c-form-fieldset {
  margin: $space-20 0 $space-30;
}

/* Form component */
.c-form {
  padding-bottom: 4.6rem;
}

/* Form element */
.c-form-element {
  margin: 0 0 $space-30;

  &--inline {
    display: inline-block;
    margin: 0 $space-10 0 0;
    vertical-align: top;
    width: auto;
  }

  &__label {
    display: block;
    font-weight: $font-weight-default;
    padding: 0 0 ($space-10 * 1.5);
    width: 100%;

    &--inline {
      display: inline-block;
      margin-right: $space-10;
      padding: ($space-20 + 4) 0 ($space-10 * 1.5);
      vertical-align: top;
      width: auto;
    }

    &--is-required {
      &::after {
        background-color: $color-danger;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 5px;
        margin: 0 -100% 0 ($space-10 / 2);
        vertical-align: .4em;
        width: 5px;
      }
    }
  }

  &__control {
    display: flex;
    width: 100%;

    &--inline {
      display: inline-flex;
      margin-right: $space-10;
      vertical-align: top;
      width: auto;
    }

    &--has-error {
      .c-form-element__input:not([type="radio"], [type="checkbox"]) {
        border-color: $color-danger;
      }
    }

    &--has-addon {
      .c-form-element__input:not(:first-child) {
        &:not([type="radio"], [type="checkbox"]) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .c-form-element__input:not(:last-child) {
        &:not([type="radio"], [type="checkbox"]) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  &__description {
    color: $color-default-30;
    font-size: $font-size-20;
    line-height: $line-height-20;
    margin-top: $space-10;

    .c-form-element__control & {
      margin-top: ($space-10 / 2);
    }
  }

  &__errors {
    color: $color-danger;
    font-size: $font-size-20;
    line-height: $line-height-20;
    margin-top: $space-10;
  }

  &__addon {
    background-color: $color-default-10;
    border-top: 1px solid $color-default-10;
    border-bottom: 1px solid $color-default-10;
    color: $color-default-base;
    flex: 0;
    font-weight: $font-weight-60;
    padding: $space-20;

    &--prefix {
      border-left: 1px solid $color-default-10;
      border-radius: $border-radius 0 0 $border-radius;
    }

    &--suffix {
      border-right: 1px solid $color-default-10;
      border-radius: 0 $border-radius $border-radius 0;
    }

    .c-form-element__control--has-error & {
      background-color: $color-default-10;
      border-color: $color-danger;
      color: $color-danger;
    }
  }
}

/* Textarea */
.c-form-element__control--textarea {
  & textarea {
    height: 200px;
  }
}

/* Checkbox and radio */
.c-form-element__control--checkbox,
.c-form-element__control--radio {
  margin-top: $space-10;
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  &.c-form-element__control--inline {
    margin: 0 $space-20 0 0;
  }

  & input[type="checkbox"],
  & input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }

  & input[type="checkbox"] + label,
  & input[type="radio"] + label {
    padding: 0;
  }

  & input[type="checkbox"] + label::before,
  & input[type="radio"] + label::before {
    background-color: white;
    border: 1px solid darken($color-default-10, 5%);
    border-radius: $border-radius;
    content: '';
    display: inline-block;
    height: 16px;
    margin-right: $space-10;
    position: relative;
    top: .1em;
    width: 16px;
  }

  & input[type="radio"] + label::before {
    border-radius: 50%;
  }

  & input[type="checkbox"]:checked + label::before,
  & input[type="radio"]:checked + label::before {
    background-color: $color-primary-base;
    background-position: center;
    background-repeat: no-repeat;
    border-color: $color-primary-base;
  }

  & input[type="checkbox"]:checked + label::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M3.302 7.616l2.024 1.372L9.826 2 12 3.151 6.372 12 2 9.849z'/%3E%3C/svg%3E");
  }

  & input[type="radio"]:checked + label::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23fff' cx='7' cy='7' r='3'/%3E%3C/svg%3E");
  }
}
