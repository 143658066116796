/* Grid */
.o-grid {
  @include grid(1);

  &--halves {
    @media #{$break-min-20} {
      @include grid(2);
    }
  }

  &--thirds {
    @media #{$break-min-20} {
      @include grid(2);
    }

    @media #{$break-min-40} {
      @include grid(3);
    }
  }

  &--fourths {
    @media #{$break-min-20} {
      @include grid(2);
    }

    @media #{$break-min-40} {
      @include grid(3);
    }

    @media #{$break-min-50} {
      @include grid(4);
    }
  }

  &--fifts {
    @media #{$break-min-20} {
      @include grid(2);
    }

    @media #{$break-min-40} {
      @include grid(5);
    }
  }

  &--section {
    margin: $space-50 0;
  }


  &__item {
    &--colspan-2 {
      grid-column: span 2;
    }
    
    &--colspan-3 {
      grid-column: span 2;
      
      @media #{$break-min-40} {
        grid-column: span 3;
      }
    }
  }
}
