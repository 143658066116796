/* Logo */
.c-logo {
  display: block;
  height: auto;
  width: 100%;

  &--header {
    margin-right: $space-40;
  }

  &__image {
    height: 100%;
    width: 100%;
  }
}
