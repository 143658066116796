@mixin button(
  $bg: $color-link,
  $border-color: $color-link,
  $color: white,
  $hover-bg: lighten($bg, 20%),
  $hover-border-color: lighten($border-color, 20%),
  $hover-color: lighten($color, 20%)
) {
  background: $bg;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  color: $color;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: $font-size-default;
  font-weight: $font-weight-60;
  line-height: $line-height-10;
  margin: 0;
  outline: none;
  padding: $space-20 $space-default;
  text-decoration: none;
  transition: color .2s, background .2s, border-color .2s;
  vertical-align: middle;

  &:hover,
  &:focus,
  &:active {
    background: $hover-bg;
    border-color: $hover-border-color;
    color: $hover-color;
  }

  &:focus,
  &:active {
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, .1);
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: .2;
    pointer-events: none;
  }
}
