/* Menu main */
.c-menu-main {
  &__list {
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline-block;
    flex: 0 0 auto;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  &__link {
    color: currentColor;
    display: block;
    font-weight: 700;
    line-height: 1;
    opacity: 0.6;
    padding: 1.25rem 0.875rem;
    position: relative;
    text-decoration: none;

    &:before {
      bottom: 0;
      background: $color-tertiary-base;
      content: '';
      height: 4px;
      left: 0;
      position: absolute;
      right: 0;
      transform: translateY(100%);
    }

    .c-menu-main__item--is-active &,
    &:hover,
    &:focus {
      color: white;
      opacity: 1;
      text-decoration: none;

      &:before {
        transform: translateY(0);
      }
    }
  }
}
