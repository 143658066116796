.c-navicon {
  display: flex;
  position: relative;

  &__button {
    background: transparent;
    border-radius: $border-radius;
    border: none;
    color: $color-default-20;
    height: 2.3125rem;
    padding: 0;
    transition: color .2s, background .2s;
    width: 2.3125rem;

    &:hover {
      background: transparentize($color-tertiary-base, 0.68);
      color: white;
    }

    &:focus {
      outline: none;
      position: relative;
    }
    
    .c-navicon--is-active & {
      background: $color-tertiary-base;
      color: white;
    }

    & span {
      background: currentColor;
      display: block;
      height: 2px;
      margin: auto;
      transition: transform .3s, width .3s;
      width: 1.125rem;

      &:first-child,
      &:last-child {
        transform-origin: left;
      }

      & + span {
        margin-top: 4px;
      }

      .c-navicon--is-active & {
        width: 17px;

        &:first-child {
          transform: translateX(2.5px) rotate(45deg);
        }
        
        &:nth-child(2) {
          transform: scale(0);
        }
        
        &:last-child {
          transform: translateX(2.5px) rotate(-45deg);
        }
      }
    }
  }

  &__flyout {
    background: white;
    border: 1px solid $color-border-30;
    border-radius: $border-radius;
    bottom: -0.5rem;
    box-shadow: 0 4px 8px 0 rgba(14,21,57,0.08);
    left: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0.75rem 0;
    position: absolute;
    transform: translateY(95%);
    transition: visibility .5s, opacity .5s, transform .3s;
    visibility: hidden;
    width: 11rem;
    will-change: scroll-position, transform, visibility, opacity;
    z-index: 99;
    
    .c-navicon--is-active & {
      opacity: 1;
      transform: translateY(100%);
      visibility: visible;
    }
  }
  
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: block;
    color: $color-default-base;

    & > a,
    & > button,
    & input[type=submit] {
      background: transparent;
      border: none;
      color: currentColor;
      cursor: pointer;
      display: block;
      font-family: inherit;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.2;
      padding: 0.4375rem 1.4375rem;
      text-align: left;
      text-decoration: none;
      width: 100%;

      &:hover {
        background: $color-bg-30;
        color: $color-link;
        text-decoration: underline;
      }
    }

    &--danger {
      color: $color-danger;
    }
  }
}