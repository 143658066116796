/* Document */
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  @include responsive-type;
}

html {
  color: $color-default-base;
  font-family: $font-family-default;
  font-weight: $font-weight-default;
  line-height: $line-height-default;
  min-height: 100%;
  text-rendering: optimizeLegibility;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

/* HTML 5 */
figcaption,
figure,
main {
  display: block;
}
