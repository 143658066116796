/* Container */
.o-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $width-50;
  padding-left: $space-20;
  padding-right: $space-20;
  width: 100%;
  
  @media #{$break-min-20} {
    padding-left: $space-default;
    padding-right: $space-default;
  }
}
