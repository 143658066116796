@mixin grid($columns: 1, $gutter: $gutter-default, $align-x: normal, $align-y: normal) {

  @if $align-y == start or $align-y == end {
    align-items: flex-#{$align-y};
  } @else {
    align-items: $align-y;
  }

  display: flex;
  flex-wrap: wrap;

  @if $align-x == start or $align-x == end {
    justify-content: flex-#{$align-x};
  } @else {
    justify-content: $align-x;
  }

  margin: (($gutter / 2) * -1);

  @supports (display: grid) {
    align-items: $align-y;
    display: grid;
    grid-gap: $gutter;
    grid-template-columns: repeat($columns, 1fr);
    justify-content: $align-x;
    margin: 0;
  }

  & > * {
    flex: 0 0 (100% / $columns);
    padding: ($gutter / 2);
    max-width: (100% / $columns);
    min-width: 0;

    @supports (display: grid) {
      flex: none;
      padding: 0;
      max-width: none;
    }
  }
}
