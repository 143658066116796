.c-page-header {
  position: relative;
  padding: $space-40 0 0;
  
  &:before {
    background-image: linear-gradient(0deg, #FFFFFF 0%, #E7EFF6 100%);
    bottom: 0;
    content: '';
    height: 7.125rem;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }
  
  &__title {
    margin: 0 0 $space-20;
    text-align: center;
    
    @media #{$break-max-20} {
      font-size: 1.75rem;
      margin: 0 $space-40 $space-20;
    }
  }
  
  &__subtitle {
    color: $color-default-20;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }

  &__back {
    color: $color-tertiary-base;
    font-size: 1.25rem;
    height: 1.875rem;
    left: 0;
    line-height: 1.875rem;
    padding-right: 0.125rem;
    position: absolute;
    display: block;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: color .2s;
    width: 1.875rem;
    
    @media #{$break-min-20} {
      height: 2.5rem;
      line-height: 2.5rem;
      width: 2.5rem;
    }

    &:before {
      border: 2px solid currentColor;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(45deg);
    }

    & .c-icon {
      transition: transform .2s;
    }

    &:hover {
      color: lighten($color-tertiary-base, 8%);

      & .c-icon {
        transform: translateX(-2px);
      }
    }
  }

  &__bottom {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;

    @media #{$break-min-20} {
      flex-flow: row wrap;
    }
  }

  &__item {
    flex: 0 0 auto;
    width: 100%;

    @media #{$break-min-20} {
      width: auto;
    }
    
    & + .c-page-header__item {
      margin-top: $space-10;
      
      @media #{$break-min-20} {
        margin-left: 0.625rem;
        margin-top: 0;
      }
    }

    &--push-left {
      margin-left: auto;
    }
  }


  &__progress {
    background: currentColor;
    bottom: 0;
    height: 2px;
    left: calc(#{$space-20} - (#{$space-20} * 2));
    position: absolute;
    width: 10%;
    
    @media #{$break-min-20} {
      left: calc(#{$space-default} - (#{$space-default} * 2));
    }

    @media (min-width: 1040px) {
      left: calc(((100vw - 980px) / 2) - (((100vw - 980px) / 2) * 2));
    }

    &:after {
      background: currentColor;
      content: '';
      height: 6px;
      position: absolute;
      right: -3px;
      top: -2px;
      transform: rotate(45deg);
      width: 6px;
    }

    &--intake {
      width: 33.33vw;
    }
    
    &--quotation {
      &-0 {
        width: 50vw;
      }
      
      &-1 {
        width: 56.5vw;
      }

      &-2 {
        width: 63.5vw;
      }

      &-3 {
        width: 70vw;
      }
    }
    
    &--order {
      &-0 {
        width: 77.77vw;
      }
      
      &-1 {
        width: 88.88vw;
      }

      &-2 {
        width: 100vw;
      }
    }
  }


  &--has-border {
    padding-bottom: $space-30;

    &:after {
      background: $color-border-base;
      bottom: 0;
      content: '';
      height: 2px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 100vw;
      z-index: -1;
    }
  }
}