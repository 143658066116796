/* Button */
.c-button {
  @include button;

  /* Sizes */
  &--small {
    font-size: $font-size-20;
    padding: $space-10 0.625rem;

    & .c-button__icon {
      font-size: 0.75rem;
    }
  }

  &--large {
    font-weight: 800;
    padding: $space-20 $space-40;
  }

  &--blue {
    background: $color-primary-base;
    border-color: $color-primary-base;

    &:hover,
    &:focus,
    &:active {
      background: lighten($color-primary-base, 4%);
      border-color: lighten($color-primary-base, 4%);
      color: white;
    }

    &.c-button--outline {
      background: transparent;
      border-width: 2px;
      border-color: $color-bg-base;
      color: $color-primary-base;
      
      &:hover,
      &:focus,
      &:active {
        background: $color-primary-base;
        border-color: $color-primary-base;
        color: white;
      }
    }
  }

  &--green {
    background: $color-secondary-base;
    border-color: $color-secondary-base;

    &:hover,
    &:focus,
    &:active {
      background: lighten($color-secondary-base, 4%);
      border-color: lighten($color-secondary-base, 4%);
      color: white;
    }
  }

  &--red {
    background: $color-tertiary-base;
    border-color: $color-tertiary-base;

    &:hover,
    &:focus,
    &:active {
      background: lighten($color-tertiary-base, 4%);
      border-color: lighten($color-tertiary-base, 4%);
      color: white;
    }
  }
  
  &--grey {
    background: $color-bg-base;
    border-color: $color-bg-base;
  
    &:hover,
    &:focus,
    &:active {
      background: lighten($color-bg-base, 4%);
      border-color: lighten($color-bg-base, 4%);
    }
    
    &.c-button--outline {
      background: transparent;
      border-width: 2px;
      color: $color-default-base;
    
      &:hover,
      &:focus,
      &:active {
        border-color: darken($color-bg-base, 10%);
      }
    }
  }

  &--fw {
    width: 100%;
    text-align: center;
  }

  &--disabled,
  &:disabled {
    background: $color-border-base!important;
    border-color: $color-border-base!important;
    color: $color-default-20!important;
    cursor: not-allowed;
    filter: none;
    opacity: 1;
  }
}
