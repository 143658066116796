.c-create {
  padding-bottom: 0.75rem;

  &:before {
    background: $color-default-base;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .5s;
    z-index: -1;
  }

  &--is-active:before {
    opacity: 0.8;
  }

  &__button {
    @include icon(before, plus);

    background: $color-secondary-base;
    border: none;
    border-radius: $border-radius;
    font-size: 0;
    font-weight: 700;
    line-height: 1.25;
    padding: 0.5rem 0.75rem;
    
    @media #{$break-min-20} {
      font-size: 1rem;
      padding: 0.5rem 1rem 0.5rem 0.75rem;
    }
    
    &:before {
      font-size: 0.875rem;
    }

    &:focus {
      outline: none;
      position: relative;

      &:after {
        background: $color-secondary-base;
        border-radius: 5px;
        bottom: -3px;
        content: '';
        display: block;
        left: -3px;
        opacity: 0.3;
        position: absolute;
        right: -3px;
        top: -3px;
        z-index: -1;
      }
    }
  }

  &__flyout {
    align-items: flex-end;
    background: $color-default-40;
    bottom: 1px;
    content: '';
    display: flex;
    flex-flow: row nowrap;
    height: 0rem;
    left: 50%;
    overflow: hidden;
    position: absolute;
    transform: translateX(-50%) translateY(100%);
    transition: height .5s;
    width: 100vw;
    z-index: 0;

    &:after {
      clear: both;
      content: '';
    }
    
    .c-create--is-active & {
      height: 26rem;

      @media #{$break-min-30} {
        height: 10.8125rem;
      }
    }

    &-inner {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  &__list {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 1.5rem 0;
    position: relative;
  }

  &__item {
    flex: 0 0 auto;
    padding: 0.625rem 0 0;
    width: 100%;
    
    @media #{$break-min-30} {
      padding: 0 0 0 0.625rem;
      width: 10.25rem;
    }
  }

  &__link {
    align-items: center;
    background: $color-default-base;
    border-radius: $border-radius;
    color: white;
    display: flex;
    padding: $space-10;
    text-decoration: none;
    transition: color .1s, background .1s;
    
    @media #{$break-min-20} {
      display: block;
      padding: 1rem;
      text-align: center;
    }

    &:hover {
      background: white;
      color: $color-link;
    }
  }

  &__icon {
    display: block;
    font-size: 2.25rem;
    opacity: 0.2;
    text-align: center;
    transition: opacity .1s;
    margin: 0 $space-20;

    .c-create__link:hover & {
      opacity: 1;
    }
  }

  &__label {
    font-weight: 700;
  }
}