@mixin responsive-type(
  $min-font: $font-size-min-base, 
  $max-font: $font-size-max-base, 
  $min-width: $width-30, 
  $max-width: $width-50
) {
  font-size: $min-font;

  @media (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    font-size: calc(#{$min-font} + (#{$max-font / 1px} - #{$min-font / 1px}) * ((100vw - #{$min-width}) / (#{$max-width / 1px} - #{$min-width / 1px})));
  }

  @media (min-width: #{$max-width}) {
    font-size: $max-font;
  }
}
