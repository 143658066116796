/* Heading */
h1, h2, h3, h4, h5, h6 {
  color: $color-default-base;
  font-family: $font-family-heading;
  font-weight: 800;
  line-height: $line-height-20;
  margin: $space-20 0 $space-30;
}
h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

/* Paragraph */
p {
  margin: $space-20 0 $space-30;
}

/* Hyperlink */
a {
  color: $color-link;
  cursor: pointer;
  text-decoration: underline currentColor;
  text-decoration-skip: ink;
  text-decoration-skip-ink: auto;

  &:hover,
  &:active,
  &:focus {
    color: darken($color-link, 20%);
    outline: none;
    text-decoration: none;
  }
}

/* Figure */
figure {
  margin: 0;
}

/* Image */
img {
  image-rendering: -webkit-optimize-contrast;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

/* Subscript & Superscript */
sup, sub {
  font-size: $font-size-10;
}

/* Address */
address {
  font-style: normal;
}

/* Line */
hr {
  border: none;
  border-bottom: 1px solid $color-default-10;
  height: 1px;
  margin: $space-40 0;
}

/* List */
ul,
ol {
  margin: $space-20 0 $space-30;
}
ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}

/* Small */
small {
  font-size: $font-size-20;
}
