.o-header {
  background: $color-default-base;
  color: white;
  position: relative;
  z-index: 99;

  &__inner {
    align-items: flex-end;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    padding-top: 3.5rem;
  }

  &__left {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    flex: 0 1 auto;
    width: calc(50% - 6.25rem);
  }
  
  &__right {
    display: flex;
    flex: 0 1 auto;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-left: auto;
    width: calc(50% - 6.25rem);
  }

  &__logo {
    bottom: -0.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 10rem;
    z-index: 10;
    
    @media #{$break-min-30} {
      width: 12.5rem;
    }
  }

  &__top {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__navicon {
    margin-right: $space-20;
    padding-bottom: 0.5rem;
    
    @media #{$break-min-30} {
      padding-bottom: 0;
    }
  }

  &__nav {
    display: none;

    @media #{$break-min-30} {
      display: block;
    }
  }
}