/* Table */
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: $space-20 0 $space-30;
  table-layout: fixed;
  width: 100%;
}
th,
td {
  empty-cells: show;
  padding: $space-20;
  text-align: left;
}
th {
  font-weight: $font-weight-60;
}
