@font-face {
  font-family: 'icons';
  src:  url('../fonts/icons/icons.eot');
  src:  url('../fonts/icons/icons.eot#iefix') format('embedded-opentype'),
        url('../fonts/icons/icons.woff2') format('woff2'),
        url('../fonts/icons/icons.woff') format('woff'),
        url('../fonts/icons/icons.ttf') format('truetype'),
        url('../fonts/icons/icons.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icons: (
  angle-down: '\e800',
  angle-left: '\e801',
  angle-right: '\e802',
  angle-up: '\e803',
  check: '\e808',
  city: '\e809',
  dots: '\e80a',
  folder: '\e80b',
  search: '\e80c',
  user: '\e80d',
  caret-down: '\e80e',
  caret-left: '\e80f',
  caret-right: '\e810',
  caret-up: '\e811',
  plus: '\e812',
  close: '\e813',
);

@mixin icon($position: before, $icon: false, $styles: true) {
  @if $position == both {
    $position: "before, &:after";
  }

  &:#{$position} {

    @if $icon {
      content: "#{map-get($icons, $icon)}";
    }
    @if $styles {
      font-family: 'icons';
      font-style: normal;
      font-weight: normal;
      height: 1em;
      speak: none;
      width: 1em;
    }

    @content;
  }
}