/* Fieldset */
fieldset {
  border: none;
  margin: $space-20 0 $space-30;
  padding: 0;
}

/* Label and legend */
label,
legend {
  cursor: pointer;
  line-height: $line-height-10;
  margin: 0;
}

/* Button */
button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

/* Form */
input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  font-family: inherit;
  outline: none;

  &:focus {
    border-color: $color-default-20;
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: .3;
    pointer-events: none;
  }
}
textarea {
  max-width: none;
  resize: vertical;
}
select:not([multiple]) {}
