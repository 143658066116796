/* Font family */
$font-family-default: 'Proxima Nova', sans-serif;
$font-family-heading: inherit;

/* Font size */
$font-size-min-base: 14px;
$font-size-max-base: 16px;
$font-size-base: 1rem;
$font-size-10: ($font-size-base * .75);
$font-size-20: ($font-size-base * .875);
$font-size-30: ($font-size-base * 1);
$font-size-40: ($font-size-base * 1.125);
$font-size-50: ($font-size-base * 1.25);
$font-size-default: $font-size-30;

$font-size-h1: ($font-size-base * 2.25);
$font-size-h2: ($font-size-base * 1.5);
$font-size-h3: ($font-size-base * 1.25);
$font-size-h4: ($font-size-base * 1.125);
$font-size-h5: ($font-size-base * 1);
$font-size-h6: ($font-size-base * 0.9);

/* Font weight */
$font-weight-30: 300;
$font-weight-40: 400;
$font-weight-60: 600;
$font-weight-70: 700;
$font-weight-80: 800;
$font-weight-default: $font-weight-40;

/* Line height */
$line-height-10: 1;
$line-height-20: 1.25;
$line-height-30: 1.5;
$line-height-40: 1.75;
$line-height-default: $line-height-40;

/* Color */
$color-default-base: #0E1539;
$color-default-10: lighten($color-default-base, 70%);
$color-default-20: #8791A4;
$color-default-30: lighten($color-default-base, 45%);
$color-default-40: #141E46;
$color-default-50: $color-default-base;
$color-default-90: darken($color-default-base, 10%);

$color-primary-base: #0160F2;
$color-primary-10: lighten($color-default-base, 75%);
$color-primary-50: $color-primary-base;

$color-secondary-base: #00B370;

$color-tertiary-base: #960141;

$color-quaternary-base: #F5E379;

$color-bg-30: #F4F8F9;
$color-bg-base: #EEF0F2;

$color-border-base: #CBD8E1;
$color-border-30: #E5EEF5;
$color-border-50: $color-border-base;

$color-link: $color-primary-base;
$color-success: $color-secondary-base;
$color-danger: $color-tertiary-base;
$color-warning: $color-quaternary-base;

/* Border radius */
$border-radius: 2px;

/* Space */
$space-base: 1.875rem;
$space-10: ($space-base * .26);
$space-20: ($space-base * .533);
$space-30: ($space-base * 1);
$space-40: ($space-base * 1.5);
$space-50: ($space-base * 2);
$space-default: $space-base;

/* Width */
$width-10: 320px;
$width-20: 560px;
$width-30: 768px;
$width-40: 992px;
$width-50: 1040px;
$width-60: 1200px;
$width-default: $width-50;

/* Gutter */
$gutter-10: $space-10;
$gutter-20: $space-20;
$gutter-30: $space-30;
$gutter-40: $space-40;
$gutter-50: $space-50;
$gutter-default: $gutter-30;

/* Breakpoints */
$break-min-10: (min-width: 320px);
$break-min-20: (min-width: 560px);
$break-min-30: (min-width: 768px);
$break-min-40: (min-width: 992px);
$break-min-50: (min-width: 1200px);
$break-min-60: (min-width: 1440px);

$break-max-10: (min-width: 319px);
$break-max-20: (max-width: 559px);
$break-max-30: (max-width: 767px);
$break-max-40: (max-width: 991px);
$break-max-50: (max-width: 1199px);

$break-only-10: (min-width: 320px) and (max-width: 319px);
$break-only-20: (min-width: 560px) and (max-width: 559px);
$break-only-30: (min-width: 768px) and (max-width: 767px);
$break-only-40: (min-width: 992px) and (max-width: 991px);
$break-only-50: (min-width: 1200px) and (max-width: 1199px);